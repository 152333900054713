import React from 'react';
import {
  Grid,
  Toolbar,
  AppBar,
  Box,
  IconButton,
  Drawer,
  Button,
} from '@material-ui/core';
import { Menu, Add } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getOr, isEmpty } from 'lodash/fp';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { navigate as navigateGatsby } from 'gatsby';
import logoPath from '../../images/logo.png';
import MobileMenu from './menu/mobileMenu';
import DefaultMenu from './menu/defaultMenu';
import { businesses, types, categories, organizations } from '../../state';

const Header = ({ children, logo, bgcolor }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { org } = queryString.parse(getOr('', 'search', location));
  const [open, setOpen] = React.useState(false);
  const organization = useSelector((state) =>
    organizations.selectors.selectOrganization(state)
  );

  const toggleDrawer = (toggle) => () => {
    setOpen(toggle);
  };

  const mobileMenu = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <MobileMenu />
    </div>
  );
  const handleClick = () => {
    const defaultType = 'a7356c91-a146-46dd-84a4-3f2e33185345';

    dispatch(types.actions.fetchTypes(defaultType));
    dispatch(types.actions.setSelectedTypeFilter(defaultType));

    dispatch(categories.actions.fetchCategories(defaultType));
    dispatch(categories.actions.setSelectedCategoryFilter(''));

    dispatch(businesses.actions.filterByType(defaultType));
    navigateGatsby(org ? `/?org=${org}` : '/');
  };

  const { imageUrl, name } = organization;

  return (
    <AppBar
      component="header"
      position="static"
      elevation={0}
      style={{ backgroundColor: bgcolor }}
    >
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={11}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {logo && (
                <Grid item xs={10} md={4}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        disableRipple
                        component="button"
                        onClick={handleClick}
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <img
                          src={logoPath}
                          alt="Pintuna logo"
                          style={{ height: '40px' }}
                        />
                      </Button>
                    </Grid>
                    {!isEmpty(organization) && (
                      <>
                        <Grid item>
                          <Box px={1}>
                            <Add />
                          </Box>
                        </Grid>
                        <Grid item>
                          <img
                            src={imageUrl}
                            alt={name}
                            style={{ height: '40px' }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={2} md={7}>
                {children || (
                  <>
                    <Box display={{ xs: 'none', md: 'block' }} align="right">
                      <DefaultMenu org={org} />
                    </Box>
                    <Box display={{ xs: 'block', md: 'none' }} align="right">
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer(true)}
                      >
                        <Menu />
                      </IconButton>
                      <Drawer
                        anchor="right"
                        open={open}
                        onClose={toggleDrawer(false)}
                      >
                        {mobileMenu}
                      </Drawer>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.defaultProps = {
  children: null,
  logo: false,
  bgcolor: '#fff',
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  logo: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Header;
