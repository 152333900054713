import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const BusinessFooter = ({ bgcolor }) => {
  const { t } = useTranslation();
  return (
    <Container
      style={{ backgroundColor: bgcolor, margin: 0, maxWidth: '100%' }}
    >
      <AppBar
        component="div"
        position="static"
        elevation={0}
        style={{ backgroundColor: bgcolor }}
      >
        <Toolbar disableGutters>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" py={3}>
                <Typography variant="caption">
                  {t('poweredBy')}{' '}
                  <Link
                    style={{ color: 'blue' }}
                    underline="always"
                    href="https://www.pintuna.com/"
                  >
                    Pintuna
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

BusinessFooter.defaultProps = {
  bgcolor: '#fff',
};

BusinessFooter.propTypes = {
  bgcolor: PropTypes.string,
};

export default BusinessFooter;
