import React from 'react';
import { Container as MUIContainer, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const Content = ({ children, sm, md, ...rest }) => (
  <MUIContainer component="main" {...rest}>
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={sm} md={md}>
        {children}
      </Grid>
    </Grid>
  </MUIContainer>
);

Content.defaultProps = {
  md: 9,
  sm: 12,
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  md: PropTypes.number,
  sm: PropTypes.number,
};

export default Content;
