import { Box, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import enFlag from '../../../images/flags/en.png';
import frFlag from '../../../images/flags/fr.png';
import esFlag from '../../../images/flags/es.png';
import { withTrans } from '../../../i18n/withTrans';
import { businesses } from '../../../state';

const useStyles = makeStyles(() => ({
  languageSelect: {
    position: 'absolute',
    right: 0,
    display: 'none',
    background: '#fff',
    zIndex: 1,
    borderRadius: 5,
    border: '1px solid #ccc',
  },
  flag: {
    float: 'left',
  },
  relative: {
    position: 'relative',
    '&:hover $languageSelect': {
      display: 'block',
    },
  },
}));

const LanguageSelect = () => {
  const classes = useStyles();
  const languages = [
    { lang: 'en-US', name: 'English', flag: enFlag },
    { lang: 'fr-CA', name: 'Francais', flag: frFlag },
    { lang: 'es-ES', name: 'Española', flag: esFlag },
  ];
  const [selectingLanguage, setSelectingLanguage] = useState(false);
  const { i18n } = useTranslation();
  const [lng, setLng] = useState(
    languages.find((l) => l.lang.slice(0, 3) === i18n.language.slice(0, 3)) ||
      languages[0]
  );

  const setLanguage = (lang) => {
    setSelectingLanguage(false);
    i18n.changeLanguage(lang);
    setLng(languages.find((l) => l.lang === lang));
  };

  const business = useSelector(businesses.selectors.selectBusiness);

  useEffect(() => {
    setLng(
      languages.find((l) => l.lang.slice(0, 3) === i18n.language.slice(0, 3)) ||
        languages[0]
    );
  }, [i18n.language]);

  useEffect(() => {
    if (businesses.culture) {
      setLanguage(business.culture);
    }
  }, [business.culture]);

  return (
    <div className={classes.relative}>
      <Button
        onClick={(e) => {
          setSelectingLanguage(!selectingLanguage);
          e.stopPropagation();
        }}
        startIcon={
          <img
            src={lng.flag}
            className={classes.flag}
            alt={lng.name}
            style={{ width: 20 }}
          />
        }
      >
        {lng.name}
      </Button>

      <Box className={classes.languageSelect} display="flex">
        {languages.map((language) => (
          <Button
            key={language.lang}
            onClick={() => setLanguage(language.lang)}
            fullWidth
            style={{ justifyContent: 'flex-start' }}
            startIcon={
              <img
                src={language.flag}
                className={classes.flag}
                style={{ width: 20 }}
                alt={language.name}
              />
            }
          >
            {language.name}
          </Button>
        ))}
      </Box>
    </div>
  );
};

export default withTrans(LanguageSelect);
