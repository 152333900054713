import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Container, Box, Grid } from '@material-ui/core';
import { AppStoreFooter, DefaultFooter } from './footer/';
import { isIos } from '../../utils';

const Footer = ({ appStore, showBlock, md }) => {
  const isIOS = isIos();
  return (
    !isIOS && (
      <Box
        component="footer"
        display={{ xs: showBlock ? 'block' : 'none', md: 'block' }}
      >
        <Container>
          <AppBar component="div" position="static" elevation={0}>
            <Toolbar disableGutters>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} md={md}>
                  <Box py={3}>
                    {appStore ? <AppStoreFooter /> : <DefaultFooter />}
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Container>
      </Box>
    )
  );
};

Footer.defaultProps = {
  appStore: false,
  showBlock: false,
  md: 9,
};

Footer.propTypes = {
  md: PropTypes.number,
  appStore: PropTypes.bool,
  showBlock: PropTypes.bool,
};

export default Footer;
