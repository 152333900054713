/* eslint-disable no-unused-expressions */
import React from 'react';
import { Grid, Toolbar, AppBar, Box, Button } from '@material-ui/core';
import { navigate as gatsbyNavigate } from 'gatsby';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
import { useLocation } from 'react-use';
// import { businesses } from '../../state';
import LanguageSelect from '../custom/LanguageSelect/LanguageSelect';
import logoPath from '../../images/logo.png';

const BusinessHeader = ({
  logoUrl,
  merchantUrl,
  merchantPintunaUrl,
  bgcolor,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const handleNavigate = () => {
    merchantPintunaUrl && gatsbyNavigate(merchantPintunaUrl, { replace: true });
  };
  const handleNavigateOutside = () => {
    merchantUrl && gatsbyNavigate(merchantUrl, { replace: true });
  };

  // const business = useSelector(businesses.selectors.selectBusiness);
  const showLanguage =
    location.pathname.indexOf('add-funds') === -1 &&
    location.pathname.indexOf('check-balance') === -1;
  //   &&
  // (business?.name === 'Traversiers Bourbonnais' ||
  //   business?.name === 'La Cabane');
  return (
    <AppBar component="header" position="static" elevation={0}>
      <Box bgcolor={bgcolor}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Button
                disableRipple
                style={{ backgroundColor: 'transparent' }}
                onClick={handleNavigate}
              >
                <img
                  src={logoUrl || logoPath}
                  alt="business logo"
                  style={{ height: '40px' }}
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                {showLanguage ? <LanguageSelect /> : null}
                {merchantUrl ? (
                  <Button
                    onClick={handleNavigateOutside}
                    style={{ width: '60px' }}
                  >
                    {' '}
                    {t('home')}{' '}
                  </Button>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

BusinessHeader.defaultProps = {
  bgcolor: '#fff',
  merchantUrl: '',
  logoUrl: '',
};

BusinessHeader.propTypes = {
  bgcolor: PropTypes.string,
  logoUrl: PropTypes.string,
  merchantUrl: PropTypes.string,
  merchantPintunaUrl: PropTypes.string.isRequired,
};

export default BusinessHeader;
