import React from 'react';
import PropTypes from 'prop-types';
// import { Link as GatsbyLink } from 'gatsby';

import { Button, makeStyles, Link, Box } from '@material-ui/core';
import { useLocation } from 'react-use';
import LanguageSelect from '../../custom/LanguageSelect/LanguageSelect';

const path = 'https://www.pintuna.com';

const useStyles = makeStyles((theme) => ({
  styledButton: {
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 400,
    marginRight: theme.spacing(0.5),
  },
}));

const DefaultMenu = ({ org }) => {
  const classes = useStyles();
  const location = useLocation();

  const showLanguage =
    location?.pathname &&
    !location.pathname.includes('legal') &&
    !location.pathname.includes('add-funds') &&
    !location.pathname.includes('check-balance');
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        {showLanguage ? <LanguageSelect /> : null}
        <Button
          className={classes.styledButton}
          // variant="contained"
          component={Link}
          color="primary"
          href={org ? `${path}/?org=${org}` : `${path}`}
          underline="none"
        >
          Home
        </Button>
      </Box>
      {/* <Button */}
      {/*  className={classes.styledButton} */}
      {/*  component={Link} */}
      {/*  href={org ? `${path}/nonprofits/?org=${org}` : `${path}/nonprofits`} */}
      {/*  underline="none" */}
      {/* > */}
      {/*  Nonprofits */}
      {/* </Button> */}
      {/* <Button */}
      {/*  className={classes.styledButton} */}
      {/*  component={Link} */}
      {/*  href={ */}
      {/*    org ? `${path}/small-business/?org=${org}` : `${path}/small-business` */}
      {/*  } */}
      {/*  underline="none" */}
      {/* > */}
      {/*  Small Businesses */}
      {/* </Button> */}
      {/* <Button */}
      {/*  className={classes.styledButton} */}
      {/*  component={Link} */}
      {/*  href={org ? `${path}/major-brands/?org=${org}` : `${path}/major-brands`} */}
      {/*  underline="none" */}
      {/* > */}
      {/*  Major Brands */}
      {/* </Button> */}
    </>
  );
};

DefaultMenu.defaultProps = {
  org: '',
};

DefaultMenu.propTypes = {
  org: PropTypes.string,
};

export default DefaultMenu;
