import React from 'react';
import { List, ListItemText, Divider, ListItem } from '@material-ui/core';
import { useLocation } from 'react-use';
import { StyledLink } from '../../custom';
import LanguageSelect from '../../custom/LanguageSelect/LanguageSelect';

const MobileMenu = () => {
  const location = useLocation();
  const showLanguage =
    location?.pathname &&
    !location.pathname.includes('legal') &&
    !location.pathname.includes('add-funds') &&
    !location.pathname.includes('check-balance');
  // const showLegalLink = location?.pathname?.indexOf('/legal') === -1;
  return (
    <>
      <List>
        <ListItem disabled>
          <ListItemText>Menu</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        {showLanguage ? (
          <ListItem button>
            <LanguageSelect />
          </ListItem>
        ) : null}
        <ListItem button>
          <ListItemText selected>
            <StyledLink to="https://www.pintuna.com/">Home</StyledLink>
          </ListItemText>
        </ListItem>
        {/* <ListItem button>
          <ListItemText>
            <StyledLink to="https://www.pintuna.com/nonprofits/">
              Nonprofits
            </StyledLink>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <StyledLink to="https://www.pintuna.com/small-business/">
              Small Businesses
            </StyledLink>
          </ListItemText>
        </ListItem> */}
        {/* <ListItem button>
          <ListItemText>Sign In</ListItemText>
        </ListItem> */}
      </List>
      {/* <List>
        <Divider />
        <ListItem disabled>
          <ListItemText>Legal</ListItemText>
        </ListItem>
        <Divider />
      </List>
      <List>
        <ListItem button>
          <ListItemText>
            <StyledLink gatsbyLink to="/legal/terms-of-use">
              Terms Of Use
            </StyledLink>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <StyledLink gatsbyLink to="/legal/privacy-policy">
              Privacy Policy
            </StyledLink>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <StyledLink gatsbyLink to="/legal/e-sign-consent">
              E-Sign Consent
            </StyledLink>
          </ListItemText>
        </ListItem>
      </List>
      <List>
        <Divider />
        <ListItem disabled>
          <ListItemText>Company</ListItemText>
        </ListItem>
        <Divider />
      </List>
      {showLegalLink && (
        <List>
          <ListItem button>
            <ListItemText>
              <StyledLink to="https://www.pintuna.com/about/">
                About Us
              </StyledLink>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemText>
              <StyledLink to="https://www.pintuna.com/faq/">FAQ</StyledLink>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemText>
              <StyledLink to="mailto:support@pintuna.com">
                Contact Us
              </StyledLink>
            </ListItemText>
          </ListItem>
        </List>
      )} */}
    </>
  );
};

export default MobileMenu;
