import React from 'react';
import { Grid, List, ListItem, Typography, Box } from '@material-ui/core';

import { useLocation } from 'react-use';
import { StyledLink } from '../../custom';

const DefaultFooter = () => {
  const location = useLocation();
  const showLegalLink = location?.pathname?.indexOf('/legal') === -1;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={6}>
        <Typography variant="body1" color="textSecondary">
          Legal
        </Typography>
        <List dense disablePadding aria-label="Small Businesses">
          <ListItem disableGutters>
            <StyledLink gatsbyLink to="/legal/terms-of-use">
              Terms Of Use
            </StyledLink>
          </ListItem>
          <ListItem disableGutters>
            <StyledLink gatsbyLink to="/legal/privacy-policy">
              Privacy Policy
            </StyledLink>
          </ListItem>
          <ListItem disableGutters>
            <StyledLink gatsbyLink to="/legal/e-sign-consent">
              E-Sign Consent
            </StyledLink>
          </ListItem>
        </List>
      </Grid>
      {showLegalLink && (
        <Grid item xs={6}>
          <Typography variant="body1" color="textSecondary">
            Company
          </Typography>
          <List dense disablePadding aria-label="Small Businesses">
            <ListItem disableGutters>
              <StyledLink to="https://www.pintuna.com/about/">
                About Us
              </StyledLink>
            </ListItem>
            <ListItem disableGutters>
              <StyledLink to="https://www.pintuna.com/faq/">FAQ</StyledLink>
            </ListItem>
            <ListItem disableGutters>
              <StyledLink to="mailto:support@pintuna.com">
                Contact Us
              </StyledLink>
            </ListItem>
          </List>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box pt={2}>
          <Typography variant="body1">© Pintuna Inc</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DefaultFooter;
