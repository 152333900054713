import React from 'react';
import { Box, Typography, CardMedia, CardActionArea } from '@material-ui/core';
import appleStoreImg from '../../../images/appleStore.png';

const AppStoreFooter = () => {
  return (
    <>
      <Box pb={2}>
        <Typography variant="body2" component="p">
          Download <b>Pintuna Wallet App</b> (by clicking on the appropriate
          logo below) to keep track of your card purchases and social impact.
        </Typography>
      </Box>

      <CardActionArea
        component="a"
        href="https://apps.apple.com/us/app/pintuna-wallet/id1532256349 "
        target="_blank"
        style={{ width: '150px' }}
      >
        <CardMedia component="img" image={appleStoreImg} title="Apple store" />
      </CardActionArea>

      <Box pt={2}>
        <Typography variant="body1">© Pintuna Inc</Typography>
      </Box>
    </>
  );
};

export default AppStoreFooter;
